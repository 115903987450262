import gql from "graphql-tag";

export const MESSAGES = gql`
  query FilterWithPaginateMessages($criteria: MessageFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateMessages(criteria: $criteria, pagination: $pagination) {
      content {
        id
        title
        body
        importance
        messageType
        timestamp
        createdDateTime
        sender {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
          employment {
            branch {
              name
            }
          }
        }
        receivers {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
          employment {
            branch {
              name
            }
          }
        }
      }
      pageInfo {
        totalElements
      }
    }
  }
`;
